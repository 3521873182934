.up {
  text-transform: uppercase;
}

.normal {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

a {
  cursor: pointer;
}

p,
a,
.n {
  font-size: 1rem;
  line-height: 1.4rem;
}

.s {
  font-size: 0.875rem;
  line-height: 1.225rem;
}

.m {
  font-size: 1.125rem;
  line-height: 1.575rem;
}

.l {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

h1 {
  font-size: 3.5rem;
  line-height: 3.85rem;
}

h2 {
  font-size: 3rem;
  line-height: 3.3rem;
}

h3 {
  font-size: 2.5rem;
  line-height: 2.75rem;
}

h4 {
  font-size: 2rem;
  line-height: 2.2rem;
}

h5 {
  font-size: 1.5rem;
  line-height: 1.65rem;
}

h6 {
  font-size: 1.25rem;
  line-height: 1.375rem;
}
