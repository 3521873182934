* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  --font: "Inter";
  font-family: var(--font);
}

body {
  width: 100%;
  height: 100vh;
}

img {
  object-fit: cover;
}

.pointer {
  cursor: pointer !important;
}

/* position */
.pr {
  position: relative;
}

.pa {
  position: absolute;
}

/* carousel */
.carousel-slider {
  z-index: 0;
}

.carousel .slide {
  text-align: start !important;
}

.carousel .control-dots {
  z-index: 0 !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0) !important;
}

/* notistack */
.notistack-SnackbarContainer {
  z-index: 10000 !important;
}

/* responsive */
@media screen and (min-width: 1000px) {
  .mobile {
    display: none !important;
  }
}

@media screen and (max-width: 1000px) {
  * {
    font-size: 12px;
  }

  .pc {
    display: none !important;
  }

  /* mui-player */
  /* .m-player .mplayer-header .title-groupt {
    position: absolute;
    right: 0;
  } */
}

/* mui-player */
.m-player .mplayer-header .title-groupt {
  display: none !important;
}

.m-player .mplayer-header .title-groupt .back-button svg {
  display: none !important;
}

.ctxmenu-wrapper {
  display: none !important;
}

/* disable mui button default style */
.MuiButtonBase-root {
  text-transform: none !important;
}

/* disable input number style */
input[type="number"] {
  -moz-appearance: textfield;
}

/* disable scroll */
::-webkit-scrollbar {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
