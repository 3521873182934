html {
  --primary: rgb(237, 37, 36);
  --primary70: rgb(237, 37, 36, 0.7);
  --secondary: #ffa630;

  --black: #0b0b0b;
  --white: #f4f4f4;

  --info: #2f80ed;
  --success: #27ae60;
  --warning: #e2b93b;
  --error: #eb5757;

  --gray1: #333333;
  --gray2: #4f4f4f;
  --gray3: #828282;
  --gray4: #bdbdbd;
  --gray5: #e0e0e0;

  --black1: #0b0b0b;
  --black2: #1d1d1d;
  --black3: #282828;
}

/* Color */
.primary {
  color: var(--primary);
}

.primary-500 {
  color: var(--primary-500);
}

.primary-700 {
  color: var(--primary-700);
}

.secondary {
  color: var(--secondary);
}

.black {
  color: var(--black);
}

.black1 {
  color: var(--black1);
}

.black2 {
  color: var(--black2);
}

.black3 {
  color: var(--black3);
}

.white {
  color: var(--white);
}

.success {
  color: var(--success);
}

.warning {
  color: var(--warning);
}

.error {
  color: var(--error);
}

/* Background Color */
.bg-primary {
  background-color: var(--primary);
}

.bg-secondary {
  background-color: var(--secondary);
}

.bg-black {
  background-color: var(--black);
}

.bg-white {
  background-color: var(--white);
}

.bg-success {
  background-color: var(--success);
}

.bg-warning {
  background-color: var(--warning);
}

.bg-error {
  background-color: var(--error);
}

/* Gray */
.gray1 {
  color: var(--gray1);
}
.gray2 {
  color: var(--gray2);
}
.gray3 {
  color: var(--gray3);
}
.gray4 {
  color: var(--gray4);
}
.gray5 {
  color: var(--gray5);
}

/* Background Gray */
.bg-gray1 {
  background-color: var(--gray1);
}
.bg-gray2 {
  background-color: var(--gray2);
}
.bg-gray3 {
  background-color: var(--gray3);
}
.bg-gray4 {
  background-color: var(--gray4);
}
.bg-gray5 {
  background-color: var(--gray5);
}
